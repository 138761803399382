<template>
  <b-row class="match-height">
    <b-col md="6">
      <b-card no-body class="overflow-hidden" >
        <b-row no-gutters >
          <b-col md="12" xl="5">
            <b-card-img :src="require('/src/assets/images/carrier-x4-7500-thumb.jpg')" alt="Image" class="rounded-0"
              style="max-height: 200px;"></b-card-img>
          </b-col>
          <b-col md="12" xl="7">
            <b-card-body title="Novidades 🚀">
              <b-card-text>
                Suporte à unidades refrigeradoras Carrier.<br>Monitore e controle suas unidades remotamente.
              </b-card-text>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
                Saiba mais
              </b-button>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <b-col md="6">
      <b-card no-body class="overflow-hidden">
        <b-row no-gutters>
          <b-col md="12" xl="5">
            <b-card-img
              src="https://www.solucoesindustriais.com.br/images/produtos/imagens_10335/expositor-refrigerado-para-hortifruti_10335_41518_1.jpg"
              alt="Image" class="rounded-0" style="max-height: 200px;"></b-card-img>
          </b-col>
          <b-col md="12" xl="7">
            <b-card-body title="Evite perdas">
              <b-card-text>
                Seja notificado em caso de falhas.<br>Mantenha o histórico de temperatura dos seus expositores.
              </b-card-text>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
                Saiba mais
              </b-button>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>

    </b-col>
  </b-row>
</template>

<script>
import {
  BCol, BRow, BCard, BCardText, BCardBody, BLink, BButton, BImg, BCardImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
    BCardImg,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => { },
    },
  },
  methods: {
    kFormatter,
  },
}
</script>
