<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col xl="4" md="6">
        <dashboard-medal />
      </b-col>


      <b-col xl="8" md="6">
        <dashboard-statistics />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
      <dashboard-news />
</b-col>
      <!-- <b-col md="6">
    <b-card title="Notificações 🔒">
      <b-card-text>Você não possui nenhuma notificação.</b-card-text>
    </b-card>
    </b-col> -->
    </b-row>

  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardText } from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import DashboardMedal from './Medal.vue'
import DashboardNews from './News.vue'
import DashboardStatistics from './Statistics.vue'
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue'
// import EcommerceGoalOverview from './Goal.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    DashboardMedal,
    DashboardStatistics,
    DashboardNews,
    BCardCode
},
  data() {
    return {
      data: {},
    }
  },
  created() {
    // data
    // this.$http.get('/ecommerce/data')
    //   .then(response => {
    //     this.data = response.data

    //     // ? Your API will return name of logged in user or you might just directly get name of logged in user
    //     // ? This is just for demo purpose
    //     const userData = getUserData()
    //     this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
    //   })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
